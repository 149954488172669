import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import NavbarComponent from "../components/Navbar"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Icon } from 'react-icons-kit'
import { check } from 'react-icons-kit/fa/check'

import { caretRight } from 'react-icons-kit/fa/caretRight'
import { facebookOfficial } from 'react-icons-kit/fa/facebookOfficial'
import { linkedinSquare } from 'react-icons-kit/fa/linkedinSquare'
import { youtubePlay } from 'react-icons-kit/fa/youtubePlay'
import { twitter } from 'react-icons-kit/fa/twitter'

import { Row, Container, Col, Tab, Nav, Button, Form } from "react-bootstrap";
import * as Styles from "../style/home.module.scss";
//Components Import

import CallToAction from "../components/button/index";
import CustomAccordian from "../components/accordian/index";

//Images and Svg Import
import logoSVG from "../assets/images/Logo.png";
import presentationImage from "../assets/images/Presentation Image.png";
import bizLiblogoPNG from "../assets/images/BizLab Logo.png";
import joSimlogoPNG from "../assets/images/Josim Logo.png";
import emailSvg from "../assets/images/email.svg";
import phoneSvg from "../assets/images/phone.svg";
import twitterSvg from "../assets/images/Twitter.svg";
import why1 from "../assets/images/why1.svg";
import why2 from "../assets/images/why2.svg";
import why3 from "../assets/images/why3.svg";
import why4 from "../assets/images/why4.svg";
import why5 from "../assets/images/why5.svg";
import why6 from "../assets/images/why6.svg";
import abstract1 from "../assets/images/Repeat Grid 3.svg";
// import abstract1 from "../assets/images/abstract1.svg";
import quote from "../assets/images/quotes.png";
import abstract2 from "../assets/images/abstract2.svg";
import abstract3 from "../assets/images/abstract3.png";
import abstract4 from "../assets/images/abstract4.png";
import angle1 from "../assets/images/angle1.svg";
import angle2 from "../assets/images/angle2.svg";
import dashboardSvgAbstract from "../assets/images/Dashboard-section-background.svg";
import about1 from "../assets/images/about1.png"
import about2 from "../assets/images/about2.png"
import about3 from "../assets/images/about3.png"
import chontactAbstract from "../assets/images/contact-abstract.png"
import chontactAbstractMobile from "../assets/images/contact-abstract2.png"
import challenge1 from "../assets/images/challenge1.png"
import challenge2 from "../assets/images/challenge2.png"
import challenge3 from "../assets/images/challenge3.png"
import challenge4 from "../assets/images/challenge4.png"
import challenge5 from "../assets/images/challenge5.png"
import challenge6 from "../assets/images/challenge6.png"
import Picture1 from "../assets/images/Picture1.png"
import client1 from "../assets/images/Skill India.png"
import client2 from "../assets/images/Startup India.png"
import client3 from "../assets/images/NSDC.png"
import client4 from "../assets/images/Digital_India.png"
import mock from "../assets/images/Mock.png"
import mock1 from "../assets/images/Mock1.png"
import mock3 from "../assets/images/Mock 3.png"
import dasboardImage from "../assets/images/Dashboard.png"
import testimonial1 from "../assets/images/testimonial1.png"
import challengesBackground from "../assets/images/challenges-background.png"
const IndexPage_Old = () => {
  const about = [
    {
      imgUrl: about1,
      heading: "India's first finance learning platform",
      details: "India's first web platform to provide complete Industry and Job experience in a virtual environment in the accounting and finance field"
    },
    {
      imgUrl: about2,
      heading: "Module based Learning",
      details: "All Accounting, tax, statutory filing and other business works have been created as modules with bills, transactions, procedural documents"
    },
    {
      imgUrl: about3,
      heading: "Actual Job Experience",
      details: "Skill elevator modules are not theory programs but is actual job works that are created in virtual companies where one has to work to gain"
    }
  ]

  const challenge = [
    {
      imgUrl: challenge1,
      heading: "No Practical Training",
      details: "Students do not have exposure to practical training in the colleges"
    },
    {
      imgUrl: challenge2,
      heading: "Colleges are not Well Equiped",
      details: "Colleges are not able to design a practical working lab for commerce and business students"
    },
    {
      imgUrl: challenge3,
      heading: "Freshers are Overlooked",
      details: "Most of the companies are looking for experienced commerce students and thereby freshers are overlooked"
    },
    {
      imgUrl: challenge4,
      heading: "Time & Cost Factor",
      details: "For Corporates & CA firms, providing hands-on job training taking more time and cost"
    },
    {
      imgUrl: challenge5,
      heading: "Steep Learning Curve",
      details: "Fast track in career has become challenging without fast track training"
    },
    {
      imgUrl: challenge6,
      heading: "No Standard Learning Source",
      details: "There is no product in the world which can simulate the actual industry works for students and corporates"
    }
  ]

  const why = [
    {
      imgUrl: why1,
      heading: "Easy to use web app",
      details: "Practical Training Software for Accounts and Tax"
    },
    {
      imgUrl: why2,
      heading: "Increase your experience",
      details: "Virtual/ Simulated Work Experience in Commerce"
    },
    {
      imgUrl: why3,
      heading: "Gain confidence",
      details: "Virtual/ Simulated Work Experience in Commerce"
    },
    {
      imgUrl: why4,
      heading: "Quicker Job Placements",
      details: "A Digital Platform for Skill Education and Virtual Entity to get work experience"
    },
    {
      imgUrl: why5,
      heading: "Various Industries in one place",
      details: "India's No.1 WebApplication to Simulate Industry works in Virtual envirnoment"
    },
    {
      imgUrl: why6,
      heading: "Online help & Video tutorials",
      details: "Great online working modules offering rich & hands-on job experience quickly"
    }
  ]
  return (

    < Layout >
      <Seo title="Home" />
      <div id="Home" className={Styles.banner}>
        <Row className={Styles.heightFull}>
          <Col md={6} className={Styles.bannerText}>
            <div>
              <h1 className={`${Styles.fontWhite} ${Styles.bannerHeading}`}>
                A Digital Platform <br />
                providing hands-on <br />
                finance job experience
              </h1>
              <p style={{ color: "#B1B9C9" }}>India's first online platform to provide on job experience in digital <br />way. Join our platform today.</p>
              <CallToAction title="Signup for free" />
            </div>
          </Col>
          <Col>3 of 3</Col>
        </Row>
      </div>
      <img src={presentationImage} className={Styles.presentationImage} />
      <div className={Styles.clientSection}>
        <Container>
          <Row>
            <Col xs={6} md={3} className="text-center align-self-center">
              <img src={client1} className="img mt-3" alt="client 1" />
            </Col>
            <Col xs={6} md={3} className="text-center align-self-center">
              <img src={client2} className="img mt-3" alt="client 2" />
            </Col>
            <Col xs={6} md={3} className="text-center align-self-center">
              <img src={client3} className="img mt-3" alt="client 3" />
            </Col>
            <Col xs={6} md={3} className="text-center align-self-center">
              <img src={client4} className="img mt-3" alt="client 4" />
            </Col>
          </Row>
        </Container>
      </div>
      <div id="About" className={Styles.aboutSection}>
        <Container>
          <h2 className={Styles.aboutHeading}>A digital platform for skill and <br />employment enhancement</h2>
          <Row>
            {about.map((aboutItem) => (
              <Col md={4}>
                <div className={Styles.aboutCards}>
                  <img src={aboutItem.imgUrl} className="img" alt="about" />
                  <div>
                    <p className={Styles.aboutTitle}>{aboutItem.heading}</p>
                    <p className={Styles.aboutDetails}>{aboutItem.details}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div id="Bizlab" className={Styles.bizLabSection}>
        <Container className={Styles.heightFull}>
          <Row className={Styles.heightFull}>
            <Col md={5} className="d-flex align-items-center ">
              <div >
                <img src={bizLiblogoPNG} className={`${Styles.bizLogo} mb-3`} alt="bizLib Logo" />
                <h1 className={Styles.bizLabHeading}>Virtual Experience of various Accounting and Tax works</h1>

                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >20 in-depth working modules</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >Get access to one Virtual company to work</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >2 years hands-on experience certificate with ProAccountantQualification</span>
                  </Col>
                </p>
                <div className="row mt-4">
                  <Col xs={7} md={6}><CallToAction title="Signup for free" size="xl" /></Col>
                  <Col xs={5} md={6}><CallToAction title="Check demo" size="xl" type="outlined" /></Col>
                </div>

              </div>
            </Col>

            <img src={mock} className={Styles.mockImage} alt="client 1" />
          </Row>
        </Container>
      </div>
      <div className={Styles.dashBoardFetures}>
        <Container className={Styles.heightFull}>
          <h2 className={Styles.aboutHeading}>Dashboard Features</h2>
          <Row className={Styles.heightFull}>
            <Col md={5} className="d-flex align-items-center ">
              <div className={Styles.mobilePadding20}>
                <CustomAccordian />
                <div style={{ width: 200, marginTop: 20 }} className="d-none d-sm-block">
                  <CallToAction title="Get Started" size="xl" type="outlined" />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <img src={dasboardImage} className={Styles.dasboardImage} />
              <div style={{ width: 200, marginTop: 20, }} className="d-block d-sm-none m-auto">
                <CallToAction title="Get Started" size="xl" type="outlined" />
              </div>
            </Col>
          </Row>
          <img src={dashboardSvgAbstract} className={Styles.dashboardAbstract} />
        </Container>
      </div>
      <div className={Styles.testimonials} >
        <Container className={Styles.heightFull}>
          <Row className={Styles.testimonialsRow}>
            <Col md={4} className="text-center d-none d-sm-block" >
              <img src={testimonial1} />
            </Col>
            <Col md={7} >
              <h4>"The hands-on experience really helped me achieve confidence"</h4>
              <p className={Styles.colorGrey} style={{ fontSize: 12, marginTop: 10, paddingRight: 30 }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum</p>
              <p className={Styles.name}>Samarth Kumar</p>
              <p style={{ fontSize: 14 }}>Student, BCom | Kolar</p>
            </Col>
            <Col md={4} className="text-center d-block d-sm-none" >
              <img src={testimonial1} />
            </Col>
            <img src={abstract3} className={Styles.abstract3} />
          </Row>
        </Container>
      </div>
      <div id="Jobsim" className={Styles.bizLabSection}>
        <Container className={Styles.heightFull}>
          <Row className={Styles.heightFull}>
            <Col md={{ span: 5, offset: 7 }} className="d-flex align-items-center ">
              <div>
                <img src={joSimlogoPNG} className={`${Styles.joSimLogo} mb-3`} alt="bizLib Logo" />
                <h1 className={Styles.bizLabHeading}>Simulated Experience of various Finance & Accounting Job roles in Industry</h1>

                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon1} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >5 On job Roles/positions</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon1} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >Get access to Job positions in Virtual company to work</span>
                  </Col>
                </p>
                <p className="row mt-3">
                  <Col xs={1} md={1}>
                    <Icon icon={check} className={Styles.checkicon1} size={9} />
                  </Col>
                  <Col xs={11} md={11}>
                    <span className={Styles.fontWhite} >Get 3 years job training experience certificate with <br />ProFinanceManager Qualification</span>
                  </Col>
                </p>
                <div className="row mt-4">
                  <Col xs={7} md={6}><CallToAction title="Signup for free" size="xl" /></Col>
                  <Col xs={5} md={6}><CallToAction title="Check demo" size="xl" type="outlined" /></Col>
                </div>

              </div>
            </Col>

            <img src={mock1} className={Styles.mockImage1} alt="client 1" />
            <img src={mock3} className={`${Styles.mock3} d-block d-sm-none `} alt="client 1" />
          </Row>
        </Container>
      </div>
      <div className={Styles.dashBoardFetures}>
        <Container className={Styles.heightFull}>
          <h2 className={Styles.aboutHeading}>Dashboard Features</h2>
          <Row className={Styles.heightFull}>
            <Col md={5} className="d-flex align-items-center ">
              <div>
                <div className={Styles.dSectionHeading}>
                  <p className="mb-0">Attendance Integration</p>
                  <Icon icon={caretRight} className={Styles.caretRightIcon} size={75.5} />
                </div>
                <p className={Styles.colorGrey} style={{ fontSize: 14, marginTop: 10 }}>Register and start enrolling your on the platform <br />immediately. No more waiting for attendance <br />integration/installation support</p>
                <p className={Styles.list} >Feature 2</p>
                <p className={Styles.list} >Feature 3</p>
                <p className={Styles.list} >Feature 4</p>
                <p className={Styles.list} >Feature 5</p>
                <div style={{ width: 200, marginTop: 20 }} className="d-none d-sm-block">
                  <CallToAction title="Get Started" size="xl" type="outlined" />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <img src={dasboardImage} className={Styles.dasboardImage} />
              <div style={{ width: 200, marginTop: 20, }} className="d-block d-sm-none m-auto">
                <CallToAction title="Get Started" size="xl" type="outlined" />
              </div>
            </Col>
          </Row>
          <img src={dashboardSvgAbstract} className={Styles.dashboardAbstract} />
        </Container>
      </div>
      <div className={Styles.testimonials} style={{ paddingBottom: 235 }}>
        <Container className={Styles.heightFull}>
          <Row className={Styles.testimonialsRow}>
            <Col md={4} className="text-center d-none d-sm-block" >
              <img src={testimonial1} />
            </Col>
            <Col md={7} >
              <h4>"The hands-on experience really helped me achieve confidence"</h4>
              <p className={Styles.colorGrey} style={{ fontSize: 12, marginTop: 10, paddingRight: 30 }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum</p>
              <p className={Styles.name}>Samarth Kumar</p>
              <p style={{ fontSize: 14 }}>Student, BCom | Kolar</p>
            </Col>
            <Col md={4} className="text-center d-block d-sm-none" >
              <img src={testimonial1} />
            </Col>
            <img src={abstract3} className={Styles.abstract3} />
          </Row>
        </Container>
        <img src={angle1} className={`${Styles.angle1} d-block d-sm-none`} />
      </div>
      <div className={Styles.challengesSection}>
        <img src={challengesBackground} className={`${Styles.challengesBackground} d-none d-sm-block`} />
        <img src={abstract4} className={Styles.abstract4} />


        <Container>
          <h2 className={Styles.challengeHeading} style={{ color: "#fff", marginBottom: 80, marginTop: -48 }}>Challenges faced by Commerce  <br />Students, Colleges And Industry</h2>
          <Row>
            {challenge.map((challengeItem) => (
              <Col md={4} className="mb-5">
                <div className={Styles.challengeCards}>
                  <img src={challengeItem.imgUrl} className="img" alt="about" />
                  <div>
                    <p className={Styles.aboutTitle}>{challengeItem.heading}</p>
                    <p className={Styles.challengeDetails}>{challengeItem.details}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div id="Why Skill Elevator" className={Styles.whySkillElevatorSection}>
        <img src={angle2} className={`${Styles.angle2} d-block d-sm-none`} />
        <Container>
          <h2 className={Styles.aboutHeading} style={{ marginBottom: 40 }}>Why Skill Elevator?</h2>
          <Row>
            {why.map((whyItem) => (
              <Col md={4} className="mb-3">
                <div className={Styles.whyCards}>
                  <img src={whyItem.imgUrl} className="mb-3" alt="why" />
                  <div>
                    <p className={Styles.aboutTitle} style={{ marginBottom: 5 }}>{whyItem.heading}</p>
                    <p className={Styles.challengeDetails}>{whyItem.details}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div className={Styles.feedback}>
        <Container className={Styles.feedbackContainer}>
          <Row>
            <Col md={{ span: 7, offset: 3 }} className={`${Styles.removePadding} mb-3`}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="Students">
                <Row className={Styles.feebackPadding}>
                  <Col md={12} className={Styles.customTabContainer} >
                    <Nav variant="pills" className={`${Styles.customNavPills} flex-row`}>
                      <Nav.Item>
                        <Nav.Link eventKey="Students" className={Styles.custonNavLink}>Students</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Job seeker" className={Styles.custonNavLink}>Job seeker</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="College" className={Styles.custonNavLink}>College</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Parents" className={Styles.custonNavLink}>Parents</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Companies" className={Styles.custonNavLink}>Companies</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col >

                  <Col md={12} >
                    <Tab.Content style={{ paddingTop: 50, paddingHorizontal: 20 }}>
                      <Tab.Pane eventKey="Students" className={Styles.fontWhite}>
                        <h3>"Got easily placed"</h3>
                        <p>Skill Elevator's module-based training helped get the experience for the job. I got easily placed and was familiar with the workflow from day 1 of the job.</p>
                        <p className={Styles.name}>Suresh Bhatkal</p>
                        <p>2020 Commerce Graduate</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Job seeker" className={Styles.fontWhite}>
                        <p>Dummy Text</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          <img src={abstract1} className={Styles.abstract1} />
          <img src={quote} className={Styles.quote} />
          <img src={Picture1} className={Styles.boyImage} />
        </Container>
      </div>
      <div id="Contact" className={Styles.contactSection}>
        <img src={chontactAbstract} className={Styles.chontactAbstract} />
        <img src={chontactAbstractMobile} className={Styles.chontactAbstractMobile} />
        <Container >
          <Row>
            <Col md={5} className={Styles.fontBlue} >
              <h1 className={Styles.aboutHeading} style={{ marginBottom: 10, textAlign: "start" }}>Contact our team</h1>
              <p style={{ color: "#172B4D" }}>We're happy to answer questions and get you <br />acquainted with Skill Elevator</p>
              <p style={{ color: "#172B4D", fontWeight: 600 }}>Support</p>
              <p className="row mt-3">
                <Col xs={1} md={1}>
                  <img src={emailSvg} />
                </Col>
                <Col xs={11} md={11}>
                  <span  >info@skillelevator.com</span>
                </Col>
              </p>
              <p className="row mt-3">
                <Col xs={1} md={1}>
                  <img src={phoneSvg} />
                </Col>
                {/* <Col xs={11} md={11}>
                  <span  >+91 77952 12345</span>
                </Col> */}
              </p>
              <p className="row mt-3 d-none d-sm-block">
                <Col xs={1} md={1}>
                  <img src={twitterSvg} />
                </Col>
                <Col xs={11} md={11}>
                  <span  >@skillelevator</span>
                </Col>
              </p>
            </Col>
            <Col md={7} >
              <div className={Styles.contactConatiner}>
                <Form>
                  <Row>
                    <Col md={6} >
                      <Form.Group >
                        <Form.Label style={{ fontWeight: 600 }}>Your First Name</Form.Label>
                        <Form.Control className={Styles.customInput} type="text" placeholder="First Name" />
                      </Form.Group>
                    </Col>
                    <Col md={6} ><Form.Group >
                      <Form.Label style={{ fontWeight: 600 }}>Your Last Name</Form.Label>
                      <Form.Control className={Styles.customInput} type="text" placeholder="Last Name" />
                    </Form.Group></Col>
                    <Col md={6} >
                      <Form.Group >
                        <Form.Label style={{ fontWeight: 600 }}>Your Email</Form.Label>
                        <Form.Control className={Styles.customInput} type="email" placeholder="your@email.com" />
                      </Form.Group>

                    </Col>
                    <Col md={6} >
                      <Form.Group >
                        <Form.Label style={{ fontWeight: 600 }}>Phone Number</Form.Label>
                        <Form.Control className={Styles.customInput} type="text" placeholder="+91" />
                      </Form.Group>
                    </Col>
                    <Col md={12} >
                      <Form.Group >
                        <Form.Label style={{ fontWeight: 600 }}>Message</Form.Label>
                        <textarea className={Styles.customTextarea} placeholder="Your message here..."></textarea>
                      </Form.Group>
                    </Col>

                    <Col md={8} >
                      <p style={{ fontSize: 13, color: "#172B4D", }}>Your information is secure. We won't ever send you spam or sell your contact details. We hate BS like that as much as you do.</p>
                    </Col>
                    <Col md={4} >
                      <CallToAction title="Submit" size="xl" />
                    </Col>
                  </Row>



                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ background: "#172B4D", paddingTop: 30, paddingBottom: 30, borderBottomWidth: .5, borderBottomColor: "white", borderBottomStyle: "solid" }}>
        <Container >
          <Row>
            <Col md={4} >
              <div >
                <img src={logoSVG} alt="Skillelevator Logo" style={{ width:250}}/>
              </div>
            </Col>
            <Col md={8}>
              <ul className={Styles.footerNavtigation}>
                <li className={Styles.listItem}>Faq</li>
                <li className={Styles.listItem}>Support</li>
                <li className={Styles.listItem}>Privacy Policy</li>
                <li className={Styles.listItem}>Terms and conditions</li>
              </ul></Col>
          </Row>
        </Container>
      </div>
      <div style={{ background: "#172B4D", }}>
        <Container >
          <Row>
            <Col md={12} style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <ul className={Styles.footerNavtigation} style={{ paddingLeft: 0 }}>
                <li className={Styles.listItem} style={{ color: "white" }}><a href="https://www.facebook.com/yourskillelevator"><Icon icon={facebookOfficial} size={15} /></a></li>
                <li className={Styles.listItem} style={{ color: "white" }}><a href="https://twitter.com/skillelevator"><Icon icon={twitter} size={15} /></a></li>
                <li className={Styles.listItem} style={{ color: "white" }}><a href="https://www.linkedin.com/company/skillelevator/"><Icon icon={linkedinSquare} size={15} /></a></li>
                <li className={Styles.listItem} style={{ color: "white" }}><a href><Icon icon={youtubePlay} size={15} /></a></li>

              </ul>
              <p style={{ color: "white", textAlign: "end" }}> © 2021, All Rights Reserved Powered By Skill Elevator</p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <h1>Hi people</h1>
      <p>Welcome to your new Gatsby site.</p>
      <p>Now go build something great.</p>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        width={300}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
      />
      <p>
        <Link to="/page-2/">Go to page 2</Link> <br />
        <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
        <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
        <Link to="/using-dsg">Go to "Using DSG"</Link>
      </p> */}
    </Layout >
  )
}
export default IndexPage_Old
